import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PokemonCard from '../components/PokemonCard';
import usePocketmonsters from '../hooks/use-pocketmonsters';

export default ({ location }) => {
  const pocketmonsters = usePocketmonsters();

  return (
    <Layout>
      <SEO title="The Original 151" />
      <aside className="mt-4 mb-24">
        <p className="mx-auto leading-loose font-press-start-2p text-xs md:text-sm text-justify px-3 sm:px-0">
          Join me on my adventure through the Kanto Region. Together we'll try
          to translate all of the orignal 151 Pokemon into Japanese and dive
          into the hidden meanings.
        </p>
      </aside>
      <ul className="flex flex-row flex-wrap justify-around sm:justify-center">
        {pocketmonsters.map(pocketmonster => (
          <PokemonCard key={pocketmonster.slug} pocketmonster={pocketmonster} />
        ))}
      </ul>
    </Layout>
  );
};
