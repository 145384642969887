import { graphql, useStaticQuery } from 'gatsby';

const usePocketmonsters = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { frontmatter: { active: { eq: true } } }
        sort: { order: ASC, fields: [frontmatter___number] }
      ) {
        nodes {
          frontmatter {
            active
            number
            slug
            name
            kana
          }
        }
      }
    }
  `);

  return data.allMdx.nodes.map(pocketmonster => ({
    active: pocketmonster.frontmatter.active,
    number: pocketmonster.frontmatter.number,
    slug: pocketmonster.frontmatter.slug,
    name: pocketmonster.frontmatter.name,
    kana: pocketmonster.frontmatter.kana,
  }));
};

export default usePocketmonsters;
