import React from 'react';
import { Link } from 'gatsby';

const InactivePokemon = () => {
  return (
    <>
      <div className="font-press-start-2p text-sm my-2">----------</div>
      <div className="font-press-start-2p text-sm">------</div>
    </>
  );
};
const ActivePokemon = ({ slug, name, kana }) => {
  return (
    <>
      <div className="font-press-start-2p text-sm my-2">
        <Link to={`/${slug}`}>{name}</Link>
      </div>
      <div className="text-sm">
        <Link to={`/${slug}`}>{kana}</Link>
      </div>
    </>
  );
};

const PokemonCard = ({ pocketmonster }) => {
  const { active, number, slug, name, kana } = pocketmonster;

  return (
    <li
      className="text-center pb-3 shadow-xl rounded-lg md:mx-3 mt-16 mb-10 flex-shrink-0 px-2 bg-white"
      style={{ width: '170px' }}
    >
      <Link to={`/${slug}`}>
        <img
          srcSet={`/img/pokemon/${number}_90.png,
        /img/pokemon/${number}_180.png 2x`}
          src={`/img/pokemon/${number}_90.png`}
          // src={`/img/pokemon/${number}.png`}
          alt="Pokemon card"
          width="90px"
          className="-mt-24 -mb-4 mx-auto inline-block"
        />
      </Link>
      <div className="font-press-start-2p text-2xs">{number}</div>
      {active ? (
        <ActivePokemon slug={slug} name={name} kana={kana} />
      ) : (
        <InactivePokemon />
      )}
    </li>
  );
};

export default PokemonCard;
